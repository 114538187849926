<template>
    <InertiaHead>
        <!-- Meta -->
        <title>{{ title }}</title>
        <template v-if="seo">
            <meta v-if="pageProps?.meta?.description" name="description" :content="pageProps.meta.description" />
            <meta v-if="pageProps?.meta?.robots" name="robots" :content="pageProps.meta.robots" />
            <link v-if="pageProps?.meta?.url" rel="canonical" :href="pageProps.meta.url" />
            <link v-if="pageProps?.meta?.next" rel="next" :href="pageProps.meta.next" />
            <link v-if="pageProps?.meta?.prev" rel="prev" :href="pageProps.meta.prev" />
            <link
                v-for="(url, locale) in pageProps?.meta?.alternate_urls ?? []"
                :key="locale"
                rel="alternate"
                :hreflang="locale"
                :href="url" />
            <!-- Open Graph -->
            <meta v-if="pageProps?.og?.type" property="og:type" :content="pageProps.og.type" />
            <meta v-if="pageProps?.og?.site_name" property="og:site_name" :content="pageProps.og.site_name" />
            <meta v-if="pageProps?.og?.title" property="og:title" :content="pageProps.og.title" />
            <meta v-if="pageProps?.og?.description" property="og:description" :content="pageProps.og.description" />
            <meta v-if="pageProps?.og?.image" property="og:image" :content="pageProps.og.image" />
            <meta v-if="pageProps?.og?.url" property="og:url" :content="pageProps.og.url" />
            <!-- Twitter card -->
            <meta v-if="pageProps?.twitter?.type" name="twitter:card" :content="pageProps.twitter.type" />
            <meta v-if="pageProps?.twitter?.title" name="twitter:title" :content="pageProps.twitter.title" />
            <meta
                v-if="pageProps?.twitter?.description"
                name="twitter:description"
                :content="pageProps.twitter.description" />
            <meta v-if="pageProps?.twitter?.image" name="twitter:image" :content="pageProps.twitter.image" />
        </template>
    </InertiaHead>
</template>

<script setup>
import {onBeforeMount} from 'vue';
import {Head as InertiaHead, usePage} from '@inertiajs/vue3';

const pageProps = usePage().props;

defineProps({
    title: {
        type: String,
        default: '',
    },
    seo: {
        type: Boolean,
        default: true,
    },
});

onBeforeMount(() => {
    // Remove HTML tags from Blade to prevent duplicates
    if (pageProps?.meta) {
        document.querySelector('meta[name="description"]')?.remove();
        document.querySelector('meta[name="robots"]')?.remove();
        document.querySelector('link[rel="canonical"]')?.remove();
        document.querySelector('link[rel="next"]')?.remove();
        document.querySelector('link[rel="prev"]')?.remove();
        document.querySelector('link[rel="alternate"]')?.remove();
    }
    if (pageProps?.og) {
        document.querySelector('meta[property="og:type"]')?.remove();
        document.querySelector('meta[property="og:site_name"]')?.remove();
        document.querySelector('meta[property="og:title"]')?.remove();
        document.querySelector('meta[property="og:description"]')?.remove();
        document.querySelector('meta[property="og:image"]')?.remove();
        document.querySelector('meta[property="og:url"]')?.remove();
    }
    if (pageProps?.twitter) {
        document.querySelector('meta[name="twitter:card"]')?.remove();
        document.querySelector('meta[name="twitter:title"]')?.remove();
        document.querySelector('meta[name="twitter:description"]')?.remove();
        document.querySelector('meta[name="twitter:image"]')?.remove();
    }
});
</script>
